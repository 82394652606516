const env = {

  api_url: "https://api.reverieglobal.com/api/admin",
  // api_url: "http://reverie-api.test/api/admin",

  google_maps_key: "AIzaSyCKU3ow8iRNEBhi1St_gMdG5Tn7_Vf3Wzo",

};

export default env;
